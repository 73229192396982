.App {
  text-align: center;
}
.bg-custom {
  background: #ffffff;
}

/* body {
  background-image: linear-gradient(45deg, #b0b0b0 25%, transparent 25%), linear-gradient(-45deg, #b0b0b0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b0b0b0 75%), linear-gradient(-45deg, transparent 75%, #b0b0b0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
} */


body * {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.logo {
  font-weight: 900;
  font-size: 45px
}

.nav-link {
  margin-left: 10px;;
}

.custom-portfolio .card {
  opacity: 1 !important;
}

.custom-box {
  background-image: linear-gradient(45deg, #b0b0b0 25%, transparent 25%), linear-gradient(-45deg, #b0b0b0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b0b0b0 75%), linear-gradient(-45deg, transparent 75%, #b0b0b0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  width: 100%;
  display: block;
}

.custom-box {
  min-height: 225px;
  display: block;
}

.img-resize img {
  transform: scale(1);
  transition: all 0.5s;
}


.img-resize:hover img {
  transform: scale(1.1);
  transition: all 0.5s;
  opacity: 0.5;
}

.img-resize:hover .img-zoom {
  display: block !important;
  position: absolute;
  top: -100%;
  z-index: 1000;
  left: 180px;
  opacity: 1;
  transition: all 0.5s;
}

.img-zoom:hover {
  display: none !important;
}


.logo sup {
  font-weight: 400;
  color: grey;
  font-size: 18px;
}

.custom-size {
  font-size: 55px;
  font-weight: 900;
}

.my-size {
  font-size: 10px;
}


.my-shadow {
  box-shadow: rgba(14, 63, 126, 0.06) 0px 1px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}
.my-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.my-shadow-3 {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.active {
  color: #000 !important;
}

.pagination .active .page-link {
  background: #000 !important;  
  border: 1px solid #000 !important;
}

.text-own {
  color: #9f9f9f;
}

.collection-disabled {
  opacity: 0.5;
  transition: all 0.5s;
}

.collection-disabled:hover {
  opacity: 1;
  transition: all 0.5s;
}

.collection-disabled:hover {
  transition: all 0.5s;
  margin-top: -5px;
}

.container-custom {
  background: #eeeded;
  box-sizing: content-box;
  width: 96%;
  margin-top: 16px;
  border-radius: 7px;
  padding-bottom: 30px;
}

.custom-glossy {
  backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}

.custom-grey {
  background: #eeeded;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logoutBtn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.loginBtn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;;
}
.loginBtnNot {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.fade-in {
  display: block !important;
  transition: all 1s;
}

.custom-border {
  border: 1px dashed #c0c0c0 !important;
}

.card {
  transition: all 0.5s;
}

.navbar-nav li:last-child {
  float: right !important;;
}

.portfolio .card:hover {
  opacity: 1 !important;
  margin-top: -10px;
  transition: all 0.5s;
}

.custom-color {
  color: #EC4899 !important;
}


@keyframes fadeIn {
  0% {
    display: none !important;
    opacity: 0;
  }
  100% {
    display: block !important;
    opacity: 1;
  }
}

/* Header animations */
@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes smoothFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slideFromLeft 0.8s ease-out forwards;
}

.animate-slide-right {
  animation: slideFromRight 0.8s ease-out forwards;
}

.animate-fade-up {
  animation: fadeInUp 0.8s ease-out forwards;
}

.animate-fade-in {
  opacity: 0;
  animation: smoothFadeIn 0.8s ease-out forwards;
}

.animation-delay-1 {
  animation-delay: 0.2s;
}

.animation-delay-2 {
  animation-delay: 0.4s;
}

.animation-delay-3 {
  animation-delay: 0.6s;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.edit-group * {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/* Replace .alert styles with Toast styles */
.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1100;
}

.toast-container.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1100;
}

.toast {
  min-width: 250px;
  background: white;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.toast .toast-header {
  border-bottom: none;
  padding: 0.75rem 1rem;
}

.toast .toast-body {
  padding: 1rem;
  word-break: break-word;
}

.custom-toast {
  min-width: 250px;
}

.confirmation-toast {
  min-width: 300px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.confirmation-toast .toast-header {
  background: #212529;
  color: white;
}

.confirmation-toast .btn-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Remove old alert styles */
.alert {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trait-group {
  display: inline-flex;
  margin-right: 5px;
  background: #efefef;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
}

.my-font {
  font-size: 0.7rem;
  color: #666;
}

.trait-group strong {
  color: #666;
  font-size: 0.8rem;
  font-weight: 500;
}

.assigned-traits .badge {
  font-size: 0.7rem;
}

.list-group-item .form-select {
  font-size: 13px;
  max-width: 120px;
}

.selected-files ul {
  margin-top: 10px;
  height: 320px;
  overflow-y: auto;
}

.progress {
  height: 25px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  height: 10px;
}

.progress-bar {
  background-color: #212529;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease;
}

/* Modal Overlay styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.modal-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

/* FAQ Accordion Styles */
.faq-accordion .card {
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.faq-accordion .card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.faq-accordion .card-header {
  background: transparent;
  border: none;
  padding: 1.2rem;
  transition: all 0.3s ease;
}

.faq-accordion .card-header:hover {
  background-color: #f8f9fa;
}

.faq-accordion .card-body {
  padding: 1.5rem;
  color: #666;
  font-size: 0.95rem;
  line-height: 1.6;
}

.faq-accordion .toggle-icon {
  transition: transform 0.3s ease;
  color: #666;
  font-size: 0.8rem;
}

.faq-accordion .toggle-icon.rotate-180 {
  transform: rotate(180deg);
}

.faq-accordion h5 {
  color: #333;
  font-weight: 600;
  font-size: 1.1rem;
}

.faq-accordion ul {
  padding-left: 1.2rem;
}

.faq-accordion li {
  margin-bottom: 0.5rem;
  color: #666;
}

/* Dark mode styles */
body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .container-custom {
  background: #2d2d2d;
}

.dark-mode .bg-light {
  background-color: #333333 !important;
}

.dark-mode .navbar-light {
  background-color: #333333;
}

.dark-mode .navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.dark-mode .custom-border {
  border-color: #939393 !important;
}

.dark-mode .bg-custom {
  background: #2d2d2d;
}

.dark-mode .text-own {
  color: #b0b0b0;
}

.dark-mode .custom-grey {
  background: #333333;
}
.dark-mode .card-body * {
  color: #fff !important;
}

.dark-mode .toast-body {
  background: #000 !important;
}

.dark-mode .muliplier {
  color: #fff !important;
  border-color: #fff !important;
}

.dark-mode .btn-dark {
  color: #fff !important;
  background-color: #0d6efd !important;
}

.dark-mode .badge {
  color: #fff !important;
  background-color: #0d6efd !important;
}

.dark-mode .form-control {
  background: #7a7a7a;
}

.dark-mode .active {
  color: #0d6efd !important;
}

.dark-mode .list-group * {
  color: #fff !important;
}

.dark-mode .pagination * {
  color: #fff !important;
}

.dark-mode .disabled *  {
  color: #000 !important;
}

.dark-mode .xmas-table * {
  color: #000 !important;
}

.dark-mode .bi-copy {
  color: #fff !important;
}

.dark-mode .bi-twitter-x {
  color: #fff !important;
}

.dark-mode .text-dark {
  color: #fff !important;
}


/* Theme toggle button */
.theme-toggle {
  background: none;
  border: none;
  padding: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: inherit;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

/* Smooth transition for theme changes */
body, .container-custom, .bg-light, .navbar-light, .custom-border {
  transition: all 0.3s ease;
}

/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
  .custom-size {
    font-size: 32px;
  }
  
  .logo {
    font-size: 32px;
  }
  
  .modal-content {
    width: 95%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

/* Print styles */
@media print {
  .no-print {
    display: none !important;
  }
  
  .container {
    width: 100%;
    max-width: none;
  }
}

.p-fileupload {
  background: transparent !important;
  text-align: center !important;
  border: 2px dashed #dee2e6 !important;
  border-radius: 6px !important;
  padding: 1.5rem !important;
}
.p-fileupload:hover {
  border-color: #adb5bd !important;
}
.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
}
.p-fileupload .p-fileupload-content {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}
.p-button {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
}
.p-button:hover {
  background: var(--bs-gray-800) !important;
  border-color: var(--bs-gray-800) !important;
}

.p-fileupload {
  background: transparent !important;
  border: 2px dashed #dee2e6 !important;
  border-radius: 6px !important;
  padding: 1rem !important;
}
.p-fileupload:hover {
  border-color: #adb5bd !important;
}
.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
}
.p-button {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
}
.p-button:hover {
  background: var(--bs-gray-800) !important;
  border-color: var(--bs-gray-800) !important;
}
.image-preview-container {
  position: relative;
}

.image-preview-hover {
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-left: 10px;
  padding: 4px;
}

.image-preview-container:hover .image-preview-hover {
  display: block;
}

/* Apply Form Styles */
.apply-form-container {
  font-size: 1.8rem;
  line-height: 2;
  width: 100%;
}

.apply-form-input {
  display: inline-block;
  border: none;
  border-bottom: 2px solid #000;
  background: transparent;
  font-size: 1.3rem;
  padding: 0 10px;
  margin: 0 10px;
  width: auto;
  min-width: 200px;
  vertical-align: middle;
}

.apply-form-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #000;
}

.my-button:hover {
  background: #000 !important;
  color: #fff !important;
}

.apply-form-input:focus-visible {
  outline: none;
  box-shadow: none;
}

.apply-form-textarea {
  display: block;
  width: 100%;
  margin: 20px 0;
  min-height: 200px;
  border: none;
  border-bottom: 2px solid #000;
  font-size: 2.5rem;
  line-height: 1.8;
  padding: 15px 0;
  background: transparent;
  resize: vertical;
}

.apply-form-file {
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 10px;
  width: auto;
  vertical-align: middle;
}

.apply-form-submit {
  font-size: 1.4rem;
  padding: 5px 25px;
  width: auto;
  min-width: 200px;
}

/* Dark mode adjustments */
.dark-mode .apply-form-input,
.dark-mode .apply-form-textarea {
  border-color: #fff;
  color: #fff;
}

.dark-mode .apply-form-input::placeholder,
.dark-mode .apply-form-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Add these new styles for the carousel */
.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #000;
  padding: 20px;
  border-radius: 50%;
}

.carousel .carousel-item {
  transition: transform 0.6s ease-in-out;
}

.carousel .carousel-item img {
  transition: opacity 0.3s ease;
}

.carousel .carousel-item img:hover {
  opacity: 1 !important;
}

/* Dark mode adjustments for carousel */
.dark-mode .carousel-control-prev-icon,
.dark-mode .carousel-control-next-icon {
  background-color: #fff;
}

/* Updated carousel styles */
.partners-carousel {
  width: 100%;
  overflow: hidden;
}

.partners-scroll {
  display: flex;
  gap: 80px;
  justify-content: space-between;
  width: 100%;
  animation: scroll 20s linear infinite;
}

.partner-logo {
  height: 80px;
  width: auto;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  flex-shrink: 0;
}

.partner-logo:hover {
  opacity: 1;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Remove all carousel controls */
.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none;
}

/* Wallet Modal Styles */
.wallet-modal {
  background: white;
  padding: 10px;
  border-radius: 15px;
  min-width: 360px;
  position: relative;
  z-index: 1060;
  animation: modalSlideIn 0.3s ease-out;
}

.dark-mode .wallet-modal {
  background: #2d2d2d;
  color: white;
}

.wallet-modal-header {
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
}

.wallet-modal-header h5 {
  margin: 0px;
  font-weight: 600;
}

.wallet-modal-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
}

.wallet-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 18px;
  background: transparent;
  width: 100%;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: walletOptionFadeIn 0.5s ease-out forwards;
}

.wallet-option:nth-child(1) { animation-delay: 0.1s; }
.wallet-option:nth-child(2) { animation-delay: 0.2s; }
.wallet-option:nth-child(3) { animation-delay: 0.3s; }
.wallet-option:nth-child(4) { animation-delay: 0.4s; }
.wallet-option:nth-child(5) { animation-delay: 0.5s; }
.wallet-option:nth-child(6) { animation-delay: 0.6s; }

.dark-mode .wallet-option {
  border-color: #4a4a4a;
}

.wallet-option:hover {
  background: #f8f9fa;
  border-color: #adb5bd;
}

.dark-mode .wallet-option:hover {
  background: #3d3d3d;
  border-color: #6c6c6c;
}

.wallet-option img {
  width: 32px;
  height: 32px;
  border-radius: 20%;
}

.wallet-option span {
  font-size: 16px;
  font-weight: 500;
}

@keyframes modalSlideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes walletOptionFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Email Verification Modal Styles */
.email-modal {
  max-width: 400px;
}

.email-verification-form {
  width: 100%;
}

.verification-input {
  letter-spacing: 2px;
  font-size: 1.2rem;
  text-align: center;
}

.form-control:disabled {
  background-color: #f8f9fa;
  opacity: 0.8;
}

.dark-mode .form-control:disabled {
  background-color: #3d3d3d;
  opacity: 0.8;
}

.dark-mode .btn-link {
  color: #fff;
}

.dark-mode .btn-link:hover {
  color: #0d6efd;
}

/* Animation for verification code input */
.verification-input {
  animation: fadeInUp 0.3s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}